* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* body {
  height: 100vh;
  width: 100vw;
} */

/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */
/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

.flexContainer {
  display: flex;
  align-items: center;
  /* background-color: #f44336; */
}

.payment-container {
  width: 100vw;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  /* align-items: center; */
  /* align-content: center; */
  justify-content: center;
  background: #b0b3fe;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ripple code */
/* button {
  background-color: #09c;
  border: none;
  border-radius: 0.25em;
  color: #fff;
  padding: 1em 2em;
} */

.ripple {
  /* box-sizing: border-box; */
  position: relative;
}

.ripple:before {
  animation: ripple 2s 1s ease-out infinite;
  border: solid 2px #decc8e;
  border-radius: 3em;
  bottom: 0;
  /* box-sizing: border-box; */
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ripple:after {
  animation: ripple 2s 2s ease-out infinite;
  border: solid 2px rgb(225, 230, 232);
  border-radius: 3em;
  bottom: 0;
  /* box-sizing: border-box; */
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.ripple:hover:before {
  animation: ripple 2s 2s ease-out infinite;
  border: solid 2px rgb(225, 230, 232);
  border-radius: 3em;
}

.ripple:hover:before {
  animation: ripple 1s 2s ease-out infinite;
  border: solid 2px #decc8e;
  border-radius: 3em;
}

@keyframes ripple {
  0% {
    opacity: 0.25;
  }
  100% {
    border-radius: 2em;
    opacity: 0;
    transform: scale(3);
  }
}
